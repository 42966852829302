import DiagnosticCode from "store/models/DiagnosticCode";
import AddDiagnosticCodeModal from "./modals/AddDiagnosticCodeModal";
import NotificationModal from "components/PromptModal";
import Actions from "modules/actions/diagnostic-code-actions";

const actions = new Actions();

export default {
  name: "DiagnosticCodes",
  components: {
    AddDiagnosticCodeModal,
    NotificationModal,
  },
  data() {
    return {
      isLoading: false,
      orderBy: {
        code: "orderByCode",
        description: "orderByDescription",
        sequence: "orderBySequence",
        validityStartDate: "orderByValidityStartDate",
        validityEndDate: "orderByValidityEndDate",
        lastModified: "orderByDate",
        status: "orderByValidityStatus",
      },
      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
      diagnosticCodes: [],
      selectedDetails: "",
      isDataLoading: false,
      initialDataLoading: false,
      isEditing: false,
      isDiagnosisModalVisible: false,
      isDiscardModalVisible: false,
      auditTrails: {
        id: null,
        isVisible: false,
      },
      columns: [
        {
          name: "code",
          align: "left",
          text: "ICD Code",
          field: "code",
          sortable: true,
        },
        {
          name: "description",
          align: "left",
          text: "Description",
          field: "description",
          sortable: true,
        },
        {
          name: "validityStartDate",
          align: "left",
          text: "Effective Date",
          field: "validityStartDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "validityEndDate",
          align: "left",
          text: "Termination Date",
          field: "validityEndDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "lastModified",
          align: "left",
          text: "Last Modified",
          field: "lastModified",
          isDateColumn: true,
          sortable: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          sortable: true,
          isStatusColumn: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      searchFilter: {
        columns: 4,
        model: DiagnosticCode,
        fields: [
          {
            label: "ICD Code",
            name: "code",
            type: "text",
            maxlength: 15,
          },
          {
            label: "Description",
            name: "description",
            type: "text",
            maxlength: 35,
            colSpan: 9,
          },
          {
            label: "Effective Date",
            name: "validityStartDate",
            type: "date",
            format: "MM/DD/YYYY",
            isInline: true,
          },
          {
            label: "Termination Date",
            name: "validityEndDate",
            type: "date",
            format: "MM/DD/YYYY",
            isInline: true,
          },
        ],
      },
      limitData: [
        "code",
        "description",
        "validityStartDate",
        "validityEndDate",
        "lastModified",
        "isActive",
        "totalCount",
      ],
      totalCount: 0,
    };
  },
  computed: {
    filteredDiagnoticCodes() {
      try {
        const data = this.diagnosticCodes.map(
          ({
            id,
            code,
            description,
            validityStartDate,
            validityEndDate,
            lastModified,
            isActive,
          }) => ({
            id,
            code,
            description,
            validityStartDate: validityStartDate
              ? this.$options.filters.getTimeDate(
                  validityStartDate,
                  "MM/DD/YYYY"
                ).zone
              : null,
            validityEndDate: validityEndDate
              ? this.$options.filters.getTimeDate(validityEndDate, "MM/DD/YYYY")
                  .zone
              : null,
            lastModified: this.$options.filters.parseDateFromString(
              lastModified,
              "MM/DD/YYYY hh:mm A"
            ),
            status: isActive ? "Active" : "Deactivated",
            isActive,
          })
        );

        return data;
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
  },
  methods: {
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    async fetchDiagnosticCodeDetails(id) {
      this.isLoading = true;
      const result = await actions.getDiagnosticCodeDetails({
        variables: {
          property: {
            id: {
              type: "UUID!",
              value: id,
            },
          },
        },
        limitData: [
          "id",
          "code",
          "description",
          "validityStartDate",
          "validityEndDate",
        ],
      });
      if (result) {
        result.validityStartDate = result.validityStartDate
          ? this.$options.filters.getTimeDate(
              result.validityStartDate,
              "MM/DD/YYYY"
            ).zone
          : "";
        result.validityEndDate = result.validityEndDate
          ? this.$options.filters.getTimeDate(
              result.validityEndDate,
              "MM/DD/YYYY"
            ).zone
          : "";

        this.selectedDetails = JSON.stringify(result);
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.handleFormModalVisibility(false, false);
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.diagnosticCodes = result;
    },
    handleFormModalVisibility(isVisible, isEditing = false) {
      this.isDiagnosisModalVisible = isVisible;
      this.isEditing = isEditing;
      if (!isVisible) {
        this.selectedDetails = "";
      }
    },
    toggleAddDiagnosticCodeModal() {
      this.handleFormModalVisibility(true, false);
    },
    toggleEditDiagnosticCodeModal(id) {
      this.handleFormModalVisibility(true, true);
      this.fetchDiagnosticCodeDetails(id);
    },
    handleDiscardModalVisibility(isVisible) {
      this.isDiscardModalVisible = isVisible;
    },
    confirmDiscardChanges() {
      this.handleDiscardModalVisibility(false);
      this.handleFormModalVisibility(false, false);
    },
    toggleChangeStatus(id, isActive) {
      this.handleChangeStatusVisibility(true, id, isActive);
    },
    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },
    async getChangeStatusAction(params) {
      const { isActive } = this.changeStatusForm;
      if (isActive) {
        return await actions.deactivateDiagnosticCode(params);
      }
      return await actions.activateDiagnosticCode(params);
    },
    async onSubmitChangeStatus() {
      const { isActive, isDisabled, id } = this.changeStatusForm;
      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        const params = {
          id: {
            type: "UUID!",
            value: id,
            unincludeToFields: true,
          },
          limitData: ["success", "errors"],
          shouldNotReturnId: true,
        };
        try {
          const { success, errors } = await this.getChangeStatusAction(params);
          if (!success || errors.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating Diagnosis Code status.";
            throw errorMesssage;
          }
          this.showNotifyMessage({
            message: `Diagnosis Code ${
              isActive ? "deactivated" : "activated"
            }.`,
            type: "success",
          });
          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };
          this.fetch();
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
    toggleAuditTrail(isVisible, id = null) {
      this.auditTrails = {
        id,
        isVisible,
      };
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetch();
      this.initialDataLoading = false;
    });
  },
};
