import DiagnosticCode from "store/models/DiagnosticCode";
import StoreActions from "store/actions";

const storeActions = new StoreActions(DiagnosticCode);
export default class DiagnosticCodeAction {
  async getAll(variables, desciption = "") {
    return await storeActions.query({
      queryName: "diagnosticCodes",
      relativeUrl: "/diagnostic-code/get-all-diagnostic-code",
      ...variables,
    }, desciption.length ? desciption : "to load diagnostic codes list."
    );
  }

  async get(params) {
    const { limitData, variables } = params;
    return await storeActions.query({
      queryName: "getDiagnosticCode",
      relativeUrl: "/diagnostic-code/get-diagnostic-code",
      limitData: limitData || null,
    }, "To fetch diagnostic code details.", {
      ...variables,
    });
  }
  
  async create(variables) {
    return await storeActions.mutation({
      queryName: "createDiagnosticCode",
      relativeUrl: "/diagnostic-code/create-diagnostic-code",
    }, "To create diagnostic code.", {
      ...variables,
    });
  }

  async update(params) {
    const { queries, variables, collectionQueries } = params;
    return await storeActions.mutation({
      queryName: "updateDiagnosticCodeDetails",
      relativeUrl: "/diagnostic-code/update-diagnostic-code",
      queries: queries || null,
      collectionQueries: collectionQueries || [],
    }, "To update diagnostic code..", {
      ...variables,
    });
  }

  async activate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation({
      queryName: "activateDiagnosticCode",
      relativeUrl: "/diagnostic-code/activate",
      limitData,
      shouldNotReturnId,
    }, "To activate diagnosis code.", {
      id,
    });
  }

  async deactivate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation({
      queryName: "deactivateDiagnosticCode",
      relativeUrl: "/diagnostic-code/deactivate",
      limitData,
      shouldNotReturnId,
    }, "To deactivate diagnosis code.", {
      id,
    });
  }
}
