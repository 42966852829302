import Actions from "modules/actions";
import DiagnosticCode from "store/actions/diagnosticCode";

const diagnosticCodeAction = new DiagnosticCode();

export default class DiagnosticCodeAction extends Actions {
  async getAllDiagnosticCodes(variables) {
    return diagnosticCodeAction.getAll(variables)
  }

  async getDiagnosticCodeDetails(variables) {
    return diagnosticCodeAction.get(variables)
  }

  async createDiagnosticCode(variables) {
    return diagnosticCodeAction.create(variables);
  }

  async updateDiagnosticCodeDetails(variables) {
    return diagnosticCodeAction.update(variables);
  }

  async activateDiagnosticCode(variables) {
    return await diagnosticCodeAction.activate(variables);
  }

  async deactivateDiagnosticCode(variables) {
    return await diagnosticCodeAction.deactivate(variables);
  }
}
