import DiagnosticCode from "store/models/DiagnosticCode";
import Actions from "modules/actions/diagnostic-code-actions";
import { isEqual } from "lodash";

const actions = new Actions();

export default {
  name: "AddDiagnosticCodeModal",
  props: {
    isEditing: {
      type: Boolean,
      required: true,
    },
    isDiagnosisModalVisible: {
      type: Boolean,
      required: true,
    },
    defaultValues: {
      type: String,
      required: true,
      default: "",
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    cancelButtonText: String,
    submitButtonText: String,
  },
  data() {
    return {
      diagnosticCodeModel: DiagnosticCode,
      menu1: false,
      menu2: false,
      date1: "",
      date2: "",
      diagnosticCode: {
        id: null,
        code: "",
        description: "",
        validityStartDate: "",
        validityEndDate: "",
      },
      isDisabled: false,
      validateProgress: {
        isValidating: false,
        progress: 0,
      },
      confirmChangesModal: {
        isVisible: false,
        message: "Are you sure you want to save the updates?",
      },
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null) {
            return (
              (val && val.id && val.id !== null) || "This field is required."
            );
          }
          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateDiagnosticCode: async (val) => {
          const result = await this.isDiagnosticCodeExisting(val);
          return result;
        },
        validDate: (val) =>
          !val || this.isValidDate(val) || "Invalid date format.",
        validValidityEndDate: (val, validityStartDate) => {
          if (!val) return true;
          const validityEndDate = new Date(val);
          const effectDate = new Date(validityStartDate);
          if (validityStartDate === null || !validityStartDate.length)
            return true;
          return (
            validityEndDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
    };
  },
  computed: {
    isFormDisabled() {
      const { isValidating } = this.validateProgress;
      return this.isLoading || isValidating || this.isDisabled;
    },
    isCodeFieldDisabled() {
      return this.isFormDisabled || this.isEditing || !!this.defaultValues;
    },
    diagnosisCodeMaxLength() {
      const { isEditing, defaultValues, diagnosticCode } = this;
      const isEditForm = isEditing || !!defaultValues;
      const defaultMaxLength = 8;
      if (isEditForm) {
        const { code } = diagnosticCode;
        if (code.length < defaultMaxLength) {
          return defaultMaxLength;
        }
        return code.length;
      }
      return defaultMaxLength;
    },
  },
  watch: {
    isDiagnosisModalVisible(newVal) {
      this.menu1 = false;
      this.menu2 = false;
      if (!newVal) {
        this.diagnosticCode = {
          code: "",
          description: "",
          validityStartDate: "",
          validityEndDate: "",
        };
        this.date1 = "";
        this.date2 = "";
      }
    },
    defaultValues(newVal) {
      if (this.isEditing && newVal) {
        this.diagnosticCode = JSON.parse(newVal);
      }
    },
  },
  methods: {
    async addDiagnosisCode() {
      try {
        const { code, description, validityStartDate, validityEndDate } =
          this.diagnosticCode;
        const params = {
          code: {
            type: "String!",
            value: code,
            unincludeToFields: true,
          },
          description: {
            type: "String!",
            value: description || "",
            unincludeToFields: true,
          },
          validityStartDate: {
            type: "String",
            value: this.$options.filters.getTimeDate(
              validityStartDate,
              "YYYY-MM-DD HH:mm:ss",
              false,
              null,
              this.getTimezoneOffset({
                date: validityStartDate,
              }),
            ).utc,
            unincludeToFields: true,
          },
          validityEndDate: {
            type: "String",
            value: validityEndDate
              ? this.$options.filters.getTimeDate(
                  validityEndDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  {
                    days: 1,
                    ...this.getTimezoneOffset({
                      date: validityEndDate,
                      minusSeconds: 1,
                    }),
                  }
                ).utc
              : "",
            unincludeToFields: true,
          },
        };
        await actions.createDiagnosticCode(params);
        this.$emit("refetchDiagnosticCodes");
        this.$emit("toggleFormModal", false);
        this.isDisabled = false;
        this.showNotifyMessage({
          message: "Records has been saved.",
          type: "success",
        });
      } catch (error) {
        this.isDisabled = false;
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
    },
    async updateDiagnosisCode() {
      this.confirmChangesModal.isVisible = false;
      try {
        const {
          id,
          description,
          validityStartDate,
          validityEndDate,
        } = this.diagnosticCode;
        const hasDateChanges = this.valueHasChanged('validityStartDate', true) || this.valueHasChanged('validityEndDate', true);
        const params = {
          variables: {
            diagnosticCodeId: {
              type: "UUID!",
              value: id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: [],
        };
        if (this.valueHasChanged('description')) {
          params.collectionQueries.push({
            updateDescription: {
              description: {
                type: "String!",
                value: description || "",
                unincludeToFields: true,
              },
            },
          });
        }
        if (hasDateChanges) {
          params.collectionQueries.push({
            updateValidityDates: {
              validityStartDate: {
                type: "String",
                value: this.$options.filters.getTimeDate(
                  validityStartDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  this.getTimezoneOffset({
                    date: validityStartDate,
                  }),
                ).utc,
                unincludeToFields: true,
              },
              validityEndDate: {
                type: "String",
                value: validityEndDate ? this.$options.filters.getTimeDate(
                  validityEndDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  {
                    days: 1,
                    ...this.getTimezoneOffset({
                      date: validityEndDate,
                      minusSeconds: 1,
                    }),
                  }
                ).utc : "",
                unincludeToFields: true,
              },
              mutationReturn: ["success", "errors"],
            },
          });
        }
        await actions.updateDiagnosticCodeDetails(params);
        this.$emit("refetchDiagnosticCodes");
        this.$emit("toggleFormModal", false);
        this.isDisabled = false;
        this.showNotifyMessage({
          message: "Records has been saved.",
          type: "success",
        });
      } catch (error) {
        this.isDisabled = false;
        this.showNotifyMessage({
          message: "Problem has occurred while updating data.",
          type: "danger",
        });
      }
    },
    async isDiagnosticCodeExisting(code) {
      if ([null, undefined, ""].includes(code))
        return "This field is required.";
      if (this.isEditing && code === JSON.parse(this.defaultValues).code)
        return true;
      const results = await actions.getAllDiagnosticCodes({
        filter: {
          code,
          enum_patternMatch: "EXACT",
          caseSensitive: false,
          archive: true,
          logicalOperator: "AND",
        },
        limitData: ["code"],
        shouldNotReturnId: true,
      });
      return results.length > 0 ? "Diagnosis Code already exists." : true;
    },
    checkForChanges() {
      if (!this.isEditing) return;
      const hasChanges = !isEqual(
        JSON.parse(this.defaultValues),
        this.diagnosticCode
      );
      return hasChanges;
    },
    valueHasChanged(field, isDate) {
      const defaultValue = JSON.parse(this.defaultValues)[field];
      if (defaultValue && isDate) {
        return !(defaultValue.split(' ')[0] === this.diagnosticCode[field]);
      }
      return !(defaultValue === this.diagnosticCode[field]);
    },
    async onCodeChange(value) {
      if (typeof value === "string") {
        const codeInput = value.replace(/[^a-zA-Z0-9]/g, "").trim();
        if (codeInput.length > 3 && codeInput.slice(3, 4) !== ".") {
          this.diagnosticCode.code = await `${codeInput.slice(
            0,
            3
          )}.${codeInput.slice(3)}`;
          return;
        }
        this.diagnosticCode.code = await codeInput;
      }
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal(dataModel, menu, date) {
      this.diagnosticCode[dataModel] = this.formatDate(date);
      this[menu] = false;
    },
    onCancel() {
      const fields = [
        "code",
        "description",
        "validityStartDate",
        "validityEndDate",
      ];
      let hasChanges = false;
      if (this.isEditing) {
        const defaultValues = JSON.parse(this.defaultValues);
        fields.forEach((field) => {
          if (defaultValues[field] !== this.diagnosticCode[field]) {
            hasChanges = true;
            return;
          }
        });
      } else {
        fields.forEach((field) => {
          if (this.diagnosticCode[field]) {
            hasChanges = true;
            return;
          }
        });
      }
      if (hasChanges) {
        this.$emit("toggleDiscardModal", true);
        return;
      }
      this.$emit("toggleFormModal", false);
    },
    async validateForm() {
      if (this.isFormDisabled) return;
      this.validateProgress.isValidating = true;
      const isFormValid = await this.$refs.addDiagnosisForm.validateAsync();
      if (isFormValid) {
        this.isDisabled = true;
      }
      this.validateProgress = {
        isValidating: false,
        progress: 100,
      };
      return isFormValid;
    },
    async onSubmit() {
      const isFormValid = await this.validateForm();
      if (!isFormValid) return;
      if (this.isEditing) {
        const hasChanges = this.checkForChanges();
        if (!hasChanges) {
          this.showNotifyMessage({
            message: "No changes were made.",
            type: "danger",
          });
          this.isDisabled = false;
          return;
        }
        this.confirmChangesModal.isVisible = true;
      } else {
        this.addDiagnosisCode();
      }
    },
  },
};
